import { useApi } from '../../providers/ApiProvider'
import { MeetingsResponse } from './typing'
import { promiseToaster, useToast } from '../../providers/Toast'

const MEETINGS_API_BASE = '/api/meetings/'

const useMeetingsApi = () => {
  const api = useApi()
  const { showToast } = useToast()

  const getAllMeetings = async (skip: number = 0, limit: number = 100) => {
    const data = await api.get<MeetingsResponse[]>(MEETINGS_API_BASE, {
      params: {
        skip,
        limit,
      },
    })
    return data.data
  }

  const getMeetingById = async (id: string) => {
    const data = await api.get<MeetingsResponse>(MEETINGS_API_BASE + id)
    return data.data
  }

  const getMyMeetings = async () => {
    const data = await api.get<MeetingsResponse[]>(`${MEETINGS_API_BASE}my`)
    return data.data
  }

  const updateMeetingNotes = promiseToaster(
    async (id: string, updatedData: MeetingsResponse) => {
      const data = await api.put<MeetingsResponse>(MEETINGS_API_BASE + id, updatedData)
      return data.data
    },
    'Meeting notes saved',
    'Unable to save metting notes',
    showToast
  )

  return {
    getAllMeetings,
    getMeetingById,
    getMyMeetings,
    updateMeetingNotes,
  }
}

export default useMeetingsApi
