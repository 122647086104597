import Box from '@mui/material/Box'
import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import PageComponent from './Page'
import Loader from '../Loader'
import ErrorBoundary from '../ErrorBoundary'
import usePagesApi from '../PageTree/api'

export default function Page() {
  const params = useParams()

  const { getPageById } = usePagesApi()

  const { data, isLoading } = useQuery({
    queryKey: ['page-data', params.id],
    queryFn: () => getPageById(params.id || ''),
  })

  if (isLoading) {
    return (
      <Box sx={{ mt: 8 }}>
        <Loader />
      </Box>
    )
  }
  if (!data) {
    return (
      <Box sx={{ mt: 8 }}>
        <Loader />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        '& .bn-default-styles': {
          fontFamily: 'Lato,sans-serif',
        },
      }}
      key={params.id}
    >
      <Box maxWidth={'lg'} width={'100%'}>
        <ErrorBoundary>
          <PageComponent data={data} />
        </ErrorBoundary>
      </Box>
    </Box>
  )
}
