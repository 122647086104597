import Box from '@mui/material/Box'
import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import MeetingPage from './MeetingPage'
import Loader from '../Loader'
import ErrorBoundary from '../ErrorBoundary'
import useMeetingsApi from '../Meetings/api'
import { Chip, Divider, Stack, Typography } from '@mui/material'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { AccessTimeOutlined, CalendarTodayOutlined } from '@mui/icons-material'

export default function NotionPageRenderer() {
  const params = useParams()

  const { getMeetingById } = useMeetingsApi()

  const { data, isLoading } = useQuery({
    queryKey: ['meeting-data', params.id],
    queryFn: () => getMeetingById(params.id || ''),
  })

  const headerData = useMemo(() => {
    const formattedData = {
      label: data?.meta.label,
      date: '',
      time: '',
      keywords: [''],
      attendees: data?.attendees || [],
      blocks: data?.blocks,
    }
    if (!data) return
    const eventTime = [...data?.properties].find((e) => e.key === 'Event time')
    if (eventTime) {
      const date = dayjs(new Date(eventTime.value))
      formattedData.date = date.format('ddd, MMM D')
      formattedData.time = date.format('h:mm A')
    }
    const keywords = [...data?.properties].find((e) => e.key === 'Keywords')
    formattedData.keywords = keywords?.value.split(',') || []
    return formattedData
  }, [data])

  if (isLoading) {
    return (
      <Box sx={{ mt: 8 }}>
        <Loader />
      </Box>
    )
  }
  if (!data) {
    return (
      <Box sx={{ mt: 8 }}>
        <Loader />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        '& .bn-default-styles': {
          fontFamily: 'Lato,sans-serif',
        },
      }}
      key={params.id}
    >
      <Box maxWidth={'lg'}>
        <ErrorBoundary>
          {headerData && (
            <Box sx={{ px: 7, mb: 5 }}>
              <Typography variant="h1" fontWeight="bold" gutterBottom>
                {headerData?.label}
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2, alignItems: 'center' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <CalendarTodayOutlined
                    sx={{ color: 'text.secondary', fontSize: '14px' }}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{ color: 'text.secondary' }}
                  >
                    {headerData.date}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <AccessTimeOutlined
                    sx={{ color: 'text.secondary', fontSize: '14px' }}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{ color: 'text.secondary' }}
                  >
                    {headerData.time}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2, alignItems: 'center' }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ color: 'text.secondary' }}
                >
                  Keywords:
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ flexWrap: 'wrap' }}
                  useFlexGap
                >
                  {[...headerData?.keywords].map((value) => (
                    <Chip
                      label={value}
                      variant="outlined"
                      size="small"
                      sx={{
                        color: 'text.secondary',
                        borderRadius: 1,
                      }}
                      key={value}
                    />
                  ))}
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: 'text.secondary' }}
                >
                  Attendees:
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ flexWrap: 'wrap' }}
                  useFlexGap
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  {[...headerData?.attendees].map((name) => (
                    <Typography
                      variant="subtitle2"
                      sx={{ color: 'text.secondary' }}
                      key={name}
                    >
                      {name}
                    </Typography>
                  ))}
                </Stack>
              </Stack>
            </Box>
          )}
          <MeetingPage data={data} />
        </ErrorBoundary>
      </Box>
    </Box>
  )
}
