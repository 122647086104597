import { FC, useEffect, useState } from 'react'
import { generateJwt } from './util'
import Loader from '../../Loader'
import { Box } from '@mui/material'
import { useParams } from 'react-router'
import useReportsApi from './api'
import { useQuery } from '@tanstack/react-query'

interface ExploDashboard
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  updateUrlParams: boolean
  isProduction: boolean
  environment?: string
  variables: string
  'dash-jwt'?: string
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': ExploDashboard
    }
  }
}

const Reports: FC = () => {
  const params = useParams()
  const [jwt, setJwt] = useState('')

  const { getReportById } = useReportsApi()

  const { data } = useQuery({
    queryKey: ['report-data', params.id],
    queryFn: () => getReportById(params.id || ''),
  })

  useEffect(() => {
    if (data) {
      generateJwt(data.explo_customer_id, data.explo_embed_id).then((e) => {
        setJwt(e)
      })
    }
  }, [data])

  useEffect(() => {
    const listener = async (e: any) => {
      // generate a new JWT here
      if (data) {
        const jwt = await generateJwt(
          data.explo_customer_id,
          data.explo_embed_id
        )
        setJwt(jwt)
        window.dispatchEvent(
          new CustomEvent('updateCustomerJwt', { detail: { jwt } })
        )
      }
    }

    window.addEventListener('customerJwtExpired', listener)
    return () => {
      window.removeEventListener('customerJwtExpired', listener)
    }
  }, [data])

  if (!jwt) {
    return <Loader />
  }

  return (
    <Box
      sx={({ typography }) => ({
        '& *': {
          fontFamily: typography.fontFamily,
        },
      })}
    >
      <explo-dashboard
        dash-jwt={jwt}
        updateUrlParams={true}
        isProduction={true}
        variables={JSON.stringify({})}
      />
    </Box>
  )
}

export default Reports
