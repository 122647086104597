import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { FC } from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import MeetingPage from '../MeetingPage'
import LandingLayout from '../LandingLayout'
import Home from '../Home'
import Meetings from '../Meetings'
import NotFoundPage from '../NotFound'
import Reports from '../MyData/Reports'
import Helpcenter from '../Helpcenter'
import MyData from '../MyData'
import Page from '../Page'
import ActionItems from '../ActionItems'

const queryClient = new QueryClient()

const App = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <LandingLayout>
            <Outlet />
          </LandingLayout>
        }
      >
        <Route path={''} element={<Home />} />
        <Route path={'my-data'} element={<Outlet />}>
          <Route path={''} element={<MyData />} />
          <Route path={':id'} element={<Reports />} />
        </Route>
        <Route path={'my-meetings'} element={<Meetings my={true} />}>
          <Route path={':id'} element={<MeetingPage />} />
        </Route>
        <Route path={'pages'} element={<Outlet />}>
          <Route path="" element={<></>} />
          <Route path={':id'} element={<Page />} />
        </Route>
        <Route path={'meetings'} element={<Meetings my={false} />}>
          <Route path={':id'} element={<MeetingPage />} />
        </Route>
        <Route path={'help-center'} element={<Helpcenter />} />
        <Route path={'actions'} element={<ActionItems />} />
        <Route path={'*'} element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

const CoreApp: FC = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  )
}
export default CoreApp
