import Typography from '@mui/material/Typography'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import { useOrganization } from '@clerk/clerk-react'
import { useQuery } from '@tanstack/react-query'
import useReportsApi from '../MyData/Reports/api'
import useMeetingsApi from '../Meetings/api'
import usePagesApi from '../PageTree/api'

const breadcrumbNameMap: { [key: string]: string } = {
  meetings: 'Meetings',
  'my-meetings': 'My Meetings',
  'my-data': 'My Data',
  reports: 'Reports',
  'help-center': 'Help Center',
  pages: 'Page',
  actions: 'My Actions',
}

const Breadcrumbs = () => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)
  const { organization } = useOrganization()

  const params = useParams()

  const { getMeetingById } = useMeetingsApi()
  const { getReportById } = useReportsApi()
  const { getPageById } = usePagesApi()

  const { data } = useQuery({
    queryKey: ['meeting-data', params.id],
    queryFn: () => getMeetingById(params.id || ''),
    enabled:
      location.pathname.includes('meetings') ||
      location.pathname.includes('my-meetings'),
  })

  const { data: reports } = useQuery({
    queryKey: ['report-data', params.id],
    queryFn: () => getReportById(params.id || ''),
    enabled: location.pathname.includes('my-data'),
  })

  const { data: pageData } = useQuery({
    queryKey: ['page-data', params.id],
    queryFn: () => getPageById(params.id || ''),
    enabled: location.pathname.includes('pages'),
  })

  const resolvedTitle = location.pathname.includes('my-data')
    ? reports?.title
    : (location.pathname.includes('pages')
        ? pageData?.label
        : data?.meta?.label) || params.id

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      sx={{ display: 'flex' }}
      separator={
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          /
        </Typography>
      }
    >
      <MuiLink
        component={RouterLink}
        color="inherit"
        to="/"
        sx={{ textDecoration: 'none' }}
      >
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {organization?.name || 'Helpcare'}
        </Typography>
      </MuiLink>
      {pathnames.map((to, index) => {
        const last = index === pathnames.length - 1
        const display = breadcrumbNameMap[to] || resolvedTitle || to

        return last ? (
          <Typography
            variant="subtitle2"
            key={to}
            sx={{ color: 'text.secondary' }}
          >
            {display}
          </Typography>
        ) : (
          <MuiLink
            component={RouterLink}
            to={to}
            key={to}
            color="inherit"
            sx={{ textDecoration: 'none' }}
          >
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              {display}
            </Typography>
          </MuiLink>
        )
      })}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs
