import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Component, ErrorInfo, ReactNode } from 'react'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    }
  }

  // Update state when an error occurs
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error, errorInfo: null }
  }

  // Capture and log error details
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })
    // You can log the error to an external error reporting service here
    console.error('Error caught by ErrorBoundary:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box>
          <Typography variant="h1">Something went wrong.</Typography>
          <Box component={'details'} sx={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error?.toString()}
            <br />
            {process.env.NODE_ENV === 'development' &&
              this.state.errorInfo?.componentStack}
          </Box>
        </Box>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
