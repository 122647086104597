import { FC, useMemo } from 'react'
import '@blocknote/core/fonts/inter.css'
import { BlockNoteView } from '@blocknote/mantine'
import '@blocknote/mantine/style.css'
import { useCreateBlockNote } from '@blocknote/react'
import { Button, useTheme } from '@mui/material'
import { Save } from '@mui/icons-material'
import { Protect } from '@clerk/clerk-react'
import useMeetingsApi from '../Meetings/api'
import { useParams } from 'react-router'
import { MeetingsResponse } from '../Meetings/typing'

interface IMeetingsProps {
  data: MeetingsResponse,
}

const MeetingPage : FC<IMeetingsProps> = ({data}) => {
  const formattedBlocks = useMemo(() => {
    for (const row of data?.blocks) {
      try {
        if (row.type === 'table') {
          ;(row.content as any).rows.forEach((e: any) => {
            if (e.cells[0] === 'Event time') {
              e.cells[1] = new Date(e.cells[1]).toLocaleString()
            }
          })
        }
      } catch (e) {
        console.error('Error formatting date :: ', e)
      }
    }
    return data?.blocks
  }, [data?.blocks])
  const editor = useCreateBlockNote({
    //@ts-ignore
    initialContent: formattedBlocks,
  })
  const { palette } = useTheme()
  const params = useParams()
  const { updateMeetingNotes } = useMeetingsApi()

  const save = () => {
    data.blocks = editor.document as any
    updateMeetingNotes(params.id || '', data)
  }

  // Renders the editor instance using a React component.
  return (
    <>
      <Protect permission="org:feature:pages_crud">
        <Button
          color="primary"
          sx={{ position: 'absolute', top: 70, right: 10, zIndex: 100 }}
          onClick={() => save()}
          endIcon={<Save />}
        >
          Save
        </Button>
      </Protect>
      <Protect
        permission="org:feature:pages_crud"
        fallback={
          <BlockNoteView
            editor={editor}
            editable={false}
            theme={palette.mode}
          />
        }
      >
        <BlockNoteView editor={editor} editable={true} theme={palette.mode} />
      </Protect>
    </>
  )
}

export default MeetingPage
