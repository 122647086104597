import { FC, useMemo } from 'react'
import '@blocknote/core/fonts/inter.css'
import { BlockNoteView } from '@blocknote/mantine'
import '@blocknote/mantine/style.css'
import { useCreateBlockNote } from '@blocknote/react'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import { BlockNoteSchema, defaultBlockSpecs } from '@blocknote/core'
import useActionsApi, { RootResponse } from './api'
import { useQuery } from '@tanstack/react-query'
import Loader from '../Loader'
import dayjs from 'dayjs'
import { Link as RouterLink } from 'react-router-dom'
import MuiLink from '@mui/material/Link'
import { Link } from '@mui/icons-material'

const schema = BlockNoteSchema.create({
  blockSpecs: {
    //first pass all the blockspecs from the built in, default block schema
    ...defaultBlockSpecs,

    // disable blocks you don't want
    file: undefined as any,
    audio: undefined as any,
    image: undefined as any,
    video: undefined as any,
  },
})

interface ActionItemBlockProps {
  data: RootResponse
}
const ActionItemBlock: FC<ActionItemBlockProps> = ({ data }) => {
  const editor = useCreateBlockNote({
    //@ts-ignore
    initialContent: data.blocks || [{ type: 'heading', content: '' }],
    schema,
  })

  const { palette } = useTheme()
  const { saveActionItem } = useActionsApi()

  const date = useMemo(
    () => dayjs(new Date(data.action_time)),
    [data.action_time]
  )

  return (
    <>
      <MuiLink
        component={RouterLink}
        color="inherit"
        to={`/meetings/${data.meeting_id}`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          textDecoration: 'none',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ ml: 6, my: 1 }}
          color={'text.secondary'}
        >
          {`${date.format('ddd, MMM D')}`}
        </Typography>
        <Link sx={{ color: 'text.secondary', height: 18 }} />
      </MuiLink>
      <BlockNoteView
        onChange={() => {
          saveActionItem(data.id, editor.document)
        }}
        style={{ width: '100%' }}
        editor={editor}
        theme={palette.mode}
      />
      <Divider />
    </>
  )
}

const ActionItems = () => {
  const { getMyActionItems } = useActionsApi()
  const { data, isLoading } = useQuery({
    queryKey: ['my-actions'],
    queryFn: getMyActionItems,
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <Box width={'100%'} display={'flex'} justifyContent={'center'}>
      <Box maxWidth={'lg'}>
        <Typography variant="h4" sx={{ ml: 6, mb: 2 }}>
          My Actions
        </Typography>
        <Divider />
        {data?.map((e, index) => <ActionItemBlock key={index} data={e} />)}
      </Box>
    </Box>
  )
}

export default ActionItems
