import { Box, Divider, Link, List, ListItem, Typography } from '@mui/material'

const Helpcenter = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        '& .bn-default-styles': {
          fontFamily: 'Lato,sans-serif',
        },
      }}
    >
      <Box maxWidth={'lg'}>
        <Box sx={{ my: 3 }}>
          <Typography variant="h1" fontWeight="bold" gutterBottom>
            Help center
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 3 }} color="text.primary">
            Welcome to Helpcare AI’s help center. If you have questions that
            aren’t answered here, please email&nbsp;
            <Link href="mailto:support@helpcare.ai" color="text.secondary">
              support@helpcare.ai.
            </Link>
          </Typography>
        </Box>
        <Divider />

        <Box sx={{ my: 3 }}>
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            🔒 Quick Note on HIPAA & Security
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 3 }} color="text.primary">
            This a fully HIPAA-compliant, healthcare focused secure platform.
            However, security is not just in the tool. Please make sure to
            follow your company’s policies around PHI, PII, and Data.
          </Typography>
        </Box>
        <Divider />

        <Box sx={{ my: 3 }}>
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            AI Meeting Notes
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 3 }} color="text.primary">
            Get
            <Link underline="none" color="text.secondary">
              &nbsp;@Hanna⟡&nbsp;
            </Link>
            to take your meeting notes, and take action items. Here’s how you do
            so:
          </Typography>
          <List sx={{ listStyleType: 'disc', marginLeft: 5 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="subtitle1" color="text.primary">
                Invite&nbsp;
                <Link
                  href="mailto:notetaker.hanna@helpcare.ai"
                  color="text.secondary"
                >
                  notetaker.hanna@helpcare.ai
                </Link>
                &nbsp;to any Zoom, Microsoft Office, Google Meets meeting as an
                attendee.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="subtitle1" color="text.primary">
                When the meeting starts, the organizer will have to “Admit”
                <Link underline="none" color="text.secondary">
                  &nbsp;@Hanna⟡&nbsp;
                </Link>
                into the meeting.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="subtitle1" color="text.primary">
                Notetaker
                <Link underline="none" color="text.secondary">
                  &nbsp;@Hanna⟡&nbsp;
                </Link>
                will not speak or chat on the meeting, but she IS listening and
                taking notes.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="subtitle1" color="text.primary">
                After the meeting
                <Link underline="none" color="text.secondary">
                  &nbsp;@Hanna⟡&nbsp;
                </Link>
                will send you an email of the notes and publish the notes in
                your My Meetings page.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography variant="subtitle1" color="text.primary">
                Lastly,
                <Link underline="none" color="text.secondary">
                  &nbsp;@Hanna⟡&nbsp;
                </Link>
                operates within the HIPAA compliant ecosystem of Helpcare AI.
                However, you should still follow company guidelines on
                discussion patient info with the appropriate audience.
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  )
}

export default Helpcenter
