import { SignInButton, useAuth } from '@clerk/clerk-react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { ResponsiveDrawer } from './ResponsiveDrawer'
import PageTree from '../PageTree'
import Loader from '../Loader'
import MuiLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Link as RouterLink } from 'react-router-dom'
import { alpha } from '@mui/material'
import { Help } from '@mui/icons-material'

const Navbar = () => {
  const { isSignedIn, isLoaded } = useAuth()

  return (
    <ResponsiveDrawer>
      <Box height={'100%'}>
        {!isLoaded && <Loader />}
        {isLoaded && !isSignedIn && (
          <>
            <SignInButton mode="modal">
              <Button variant="contained" size="small">
                Log In
              </Button>
            </SignInButton>
            <SignInButton mode="modal">
              <Button size="small">Get into your space</Button>
            </SignInButton>
          </>
        )}
        {isSignedIn && isLoaded && (
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            height={'100%'}
          >
            <PageTree />
            <Box
              sx={(theme) => ({
                px: 2,
                py: 1,
                '&: hover': {
                  background: alpha(theme.palette.text.primary, 0.08),
                },
              })}
            >
              <MuiLink
                component={RouterLink}
                color="inherit"
                to="/help-center"
                sx={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                <Help
                  fontSize="small"
                  sx={{ mr: 1, color: 'text.secondary' }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ color: 'text.secondary' }}
                >
                  Help Center
                </Typography>
              </MuiLink>
            </Box>
          </Box>
        )}
        {/* <ThemeToggleButton /> */}
      </Box>
    </ResponsiveDrawer>
  )
}

export default Navbar
