import { FC } from 'react'
import '@blocknote/core/fonts/inter.css'
import { BlockNoteView } from '@blocknote/mantine'
import '@blocknote/mantine/style.css'
import { useCreateBlockNote } from '@blocknote/react'
import { Button, useTheme } from '@mui/material'
import usePagesApi, { Page } from '../PageTree/api'
import { Save } from '@mui/icons-material'
import { Protect } from '@clerk/clerk-react'
import { BlockNoteSchema, defaultBlockSpecs } from '@blocknote/core'

const schema = BlockNoteSchema.create({
  blockSpecs: {
    //first pass all the blockspecs from the built in, default block schema
    ...defaultBlockSpecs,

    // disable blocks you don't want
    file: undefined as any,
    audio: undefined as any,
    image: undefined as any,
    video: undefined as any,
  },
})

const MeetingPage: FC<{
  data: Page
}> = ({ data }) => {
  const editor = useCreateBlockNote({
    //@ts-ignore
    initialContent: data.blocks || [{ type: 'heading', content: 'Untitled' }],
    schema,
  })
  const { palette } = useTheme()
  const { savePage } = usePagesApi()

  const save = () => {
    savePage({
      ...data,
      blocks: editor.document as any,
    })
  }
  // Renders the editor instance using a React component.
  return (
    <>
      <Protect permission="org:feature:pages_crud">
        <Button
          color="primary"
          sx={{ position: 'absolute', top: 70, right: 10, zIndex: 100 }}
          onClick={() => save()}
          endIcon={<Save />}
        >
          Save
        </Button>
      </Protect>
      <Protect
        permission="org:feature:pages_crud"
        fallback={
          <BlockNoteView
            style={{ width: '100%' }}
            editor={editor}
            editable={false}
            theme={palette.mode}
          />
        }
      >
        <BlockNoteView
          style={{ width: '100%' }}
          editor={editor}
          theme={palette.mode}
        />
      </Protect>
    </>
  )
}

export default MeetingPage
